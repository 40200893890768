import { RouteRecordRaw } from 'vue-router'
import { RoleName } from '@/api/useUsers.types'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('audit'),
    name: Route.Audit,
    component: () => import('@/modules/audit/views/Audit.vue'),
    meta: {
      authorize: [RoleName.Admin],
    },
  },
]
