import { RouteRecordRaw } from 'vue-router'
import { ActionCategory, ActionScope } from '@/api/useActions.types'
import { Feature } from '@/api/useCompany.types'
import { RoleName } from '@/api/useUsers.types'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('assessment'),
    name: Route.Assessment,
    component: () => import('@/modules/assessment/views/Assessment.vue'),
    redirect: { name: Route.AssessmentActions },
    meta: {
      requireFeatures: [Feature.Reduction],
    },
    children: [
      {
        path: 'emissions',
        name: Route.AssessmentEmissions,
        component: () =>
          import('@/modules/assessment/views/AssessmentEmissions.vue'),
        meta: {
          authorize: [RoleName.Admin],
        },
      },
      {
        path: 'reduction-path',
        name: Route.AssessmentReductionPath,
        component: () =>
          import('@/modules/assessment/views/AssessmentReductionPath.vue'),
        meta: {
          authorize: [RoleName.Admin],
        },
      },
      {
        path: 'calculations',
        name: Route.AssessmentCalculations,
        component: () =>
          import('@/modules/assessment/views/AssessmentCalculations.vue'),
        meta: {
          authorize: [RoleName.Admin],
        },
      },
      {
        path: 'actions',
        name: Route.AssessmentActions,
        redirect: {
          name: Route.AssessmentActionsScopeDefault,
        },
        component: () =>
          import('@/modules/assessment/views/AssessmentActions.vue'),
        meta: {
          preventScrollBetweenChildren: true,
        },
        children: [
          {
            path: 'category',
            name: Route.AssessmentActionsCategoryDefault,
            redirect: {
              name: Route.AssessmentActionsCategory,
              params: {
                categoryId: ActionCategory.Employees,
              },
            },
            children: [
              {
                path: ':categoryId',
                name: Route.AssessmentActionsCategory,
                component: () =>
                  import(
                    '@/modules/assessment/views/AssessmentActionsCategory.vue'
                  ),
                meta: {
                  authorize: [RoleName.Admin],
                },
              },
            ],
          },

          {
            path: 'scope',
            name: Route.AssessmentActionsScopeDefault,
            redirect: {
              name: Route.AssessmentActionsScope,
              params: {
                scope: ActionScope.First,
              },
            },
            children: [
              {
                path: ':scope',
                name: Route.AssessmentActionsScope,
                component: () =>
                  import(
                    '@/modules/assessment/views/AssessmentActionsScope.vue'
                  ),
                meta: {
                  authorize: [RoleName.Admin],
                },
              },
            ],
          },
        ],
      },
    ],
  },
]
